<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="btn-row">
        <md-button class="md-success" @click="onPassportPrint">
          {{ $t('buttons.print') }}
        </md-button>
      </div>
      <component
        v-bind:is="currentPassport"
        id="passportForm"
        :pas_details="dataObject"
        v-if="!isDataLoading"
      ></component>
    </div>
  </div>
</template>

<script>
import bridge_passport_form from '@/components/Passports/bridge'
import tunnel_passport_form from '@/components/Passports/tunnel'

export default {
  data() {
    return {
      dataObject: {},
      isDataLoading: true
    }
  },
  name: 'passport-form',
  props: ['uuid', 'target'],
  components: {
    bridge_passport_form,
    tunnel_passport_form
  },
  async created() {
    this.isDataLoading = true
    try {
      const act = `load_${this.target}_details`.toUpperCase()
      const res = await this.$store.dispatch(act, this.uuid)
      this.dataObject = {...res}
    } catch (err) {
      console.log(err)
    } finally {
      this.isDataLoading = false
    }
  },
  computed: {
    currentPassport() {
      return `${this.target}_passport_form`
    }
  },
  methods: {
    onPassportPrint() {
      const options = {
        name: '_blank',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          '/print.css',
          'https://vuematerial.io/vue-material.min.css',
          'https://fonts.googleapis.com/icon?family=Material+Icons'
        ]
      }
      this.$htmlToPaper('passportForm', options)
    }
  }
}
</script>

<style lang="scss">
.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
